module.exports = {
    // 转义字符（全端支持）
    escapeHtml (str) {
        var arrEntities = {
            'nbsp': ' ',
            'iexcl': '¡',
            'cent': '¢',
            'pound': '£',
            'curren': '¤',
            'yen': '¥',
            'brvbar': '¦',
            'sect': '§',
            'uml': '¨',
            'copy': '©',
            'ordf': 'ª',
            'laquo': '«',
            'not': '¬',
            'shy': '',
            'reg': '®',
            'macr': '¯',
            'deg': '°',
            'plusmn': '±',
            'sup2': '²',
            'sup3': '³',
            'acute': '´',
            'micro': 'µ',
            'para': '¶',
            'middot': '·',
            'cedil': '¸',
            'sup1': '¹',
            'ordm': 'º',
            'raquo': '»',
            'frac14': '¼',
            'frac12': '½',
            'frac34': '¾',
            'iquest': '¿',
            'Agrave': 'À',
            'Aacute': 'Á',
            'Acirc': 'Â',
            'Atilde': 'Ã',
            'Auml': 'Ä',
            'Aring': 'Å',
            'AElig': 'Æ',
            'Ccedil': 'Ç',
            'Egrave': 'È',
            'Eacute': 'É',
            'Ecirc': 'Ê',
            'Euml': 'Ë',
            'Igrave': 'Ì',
            'Iacute': 'Í',
            'Icirc': 'Î',
            'Iuml': 'Ï',
            'ETH': 'Ð',
            'Ntilde': 'Ñ',
            'Ograve': 'Ò',
            'Oacute': 'Ó',
            'Ocirc': 'Ô',
            'Otilde': 'Õ',
            'Ouml': 'Ö',
            'times': '×',
            'Oslash': 'Ø',
            'Ugrave': 'Ù',
            'Uacute': 'Ú',
            'Ucirc': 'Û',
            'Uuml': 'Ü',
            'Yacute': 'Ý',
            'THORN': 'Þ',
            'szlig': 'ß',
            'agrave': 'à',
            'aacute': 'á',
            'acirc': 'â',
            'atilde': 'ã',
            'auml': 'ä',
            'aring': 'å',
            'aelig': 'æ',
            'ccedil': 'ç',
            'egrave': 'è',
            'eacute': 'é',
            'ecirc': 'ê',
            'euml': 'ë',
            'igrave': 'ì',
            'iacute': 'í',
            'icirc': 'î',
            'iuml': 'ï',
            'eth': 'ð',
            'ntilde': 'ñ',
            'ograve': 'ò',
            'oacute': 'ó',
            'ocirc': 'ô',
            'otilde': 'õ',
            'ouml': 'ö',
            'divide': '÷',
            'oslash': 'ø',
            'ugrave': 'ù',
            'uacute': 'ú',
            'ucirc': 'û',
            'uuml': 'ü',
            'yacute': 'ý',
            'thorn': 'þ',
            'yuml': 'ÿ',
            'fnof': 'ƒ',
            'Alpha': 'Α',
            'Beta': 'Β',
            'Gamma': 'Γ',
            'Delta': 'Δ',
            'Epsilon': 'Ε',
            'Zeta': 'Ζ',
            'Eta': 'Η',
            'Theta': 'Θ',
            'Iota': 'Ι',
            'Kappa': 'Κ',
            'Lambda': 'Λ',
            'Mu': 'Μ',
            'Nu': 'Ν',
            'Xi': 'Ξ',
            'Omicron': 'Ο',
            'Pi': 'Π',
            'Rho': 'Ρ',
            'Sigma': 'Σ',
            'Tau': 'Τ',
            'Upsilon': 'Υ',
            'Phi': 'Φ',
            'Chi': 'Χ',
            'Psi': 'Ψ',
            'Omega': 'Ω',
            'alpha': 'α',
            'beta': 'β',
            'gamma': 'γ',
            'delta': 'δ',
            'epsilon': 'ε',
            'zeta': 'ζ',
            'eta': 'η',
            'theta': 'θ',
            'iota': 'ι',
            'kappa': 'κ',
            'lambda': 'λ',
            'mu': 'μ',
            'nu': 'ν',
            'xi': 'ξ',
            'omicron': 'ο',
            'pi': 'π',
            'rho': 'ρ',
            'sigmaf': 'ς',
            'sigma': 'σ',
            'tau': 'τ',
            'upsilon': 'υ',
            'phi': 'φ',
            'chi': 'χ',
            'psi': 'ψ',
            'omega': 'ω',
            'thetasym': '?',
            'upsih': '?',
            'piv': '?',
            'bull': '•',
            'hellip': '…',
            'prime': '′',
            'Prime': '″',
            'oline': '‾',
            'frasl': '⁄',
            'weierp': '℘',
            'image': 'ℑ',
            'real': 'ℜ',
            'trade': '™',
            'alefsym': 'ℵ',
            'larr': '←',
            'uarr': '↑',
            'rarr': '→',
            'darr': '↓',
            'harr': '↔',
            'crarr': '↵',
            'lArr': '⇐',
            'uArr': '⇑',
            'rArr': '⇒',
            'dArr': '⇓',
            'hArr': '⇔',
            'forall': '∀',
            'part': '∂',
            'exist': '∃',
            'empty': '∅',
            'nabla': '∇',
            'isin': '∈',
            'notin': '∉',
            'ni': '∋',
            'prod': '∏',
            'sum': '∑',
            'minus': '−',
            'lowast': '∗',
            'radic': '√',
            'prop': '∝',
            'infin': '∞',
            'ang': '∠',
            'and': '∧',
            'or': '∨',
            'cap': '∩',
            'cup': '∪',
            'int': '∫',
            'there4': '∴',
            'sim': '∼',
            'cong': '∝',
            'asymp': '≈',
            'ne': '≠',
            'equiv': '≡',
            'le': '≤',
            'ge': '≥',
            'sub': '⊂',
            'sup': '⊃',
            'nsub': '⊄',
            'sube': '⊆',
            'supe': '⊇',
            'oplus': '⊕',
            'otimes': '⊗',
            'perp': '⊥',
            'sdot': '⋅',
            'lceil': '?',
            'rceil': '?',
            'lfloor': '?',
            'rfloor': '?',
            'lang': '?',
            'rang': '?',
            'loz': '◊',
            'spades': '♠',
            'clubs': '♣',
            'hearts': '♥',
            'diams': '♦',
            'quot': '"',
            'amp': '',
            'lt': '<',
            'gt': '>',
            'OElig': 'Œ',
            'oelig': 'œ',
            'Scaron': 'Š',
            'scaron': 'š',
            'Yuml': 'Ÿ',
            'circ': 'ˆ',
            'tilde': '˜',
            'ensp': '',
            'emsp': '',
            'thinsp': '',
            'zwnj': '',
            'zwj': '‍',
            '‎lrm': '',
            'rlm': '',
            'ndash': '–',
            'mdash': '—',
            'lsquo': '‘',
            'rsquo': '’',
            'sbquo': '‚',
            'ldquo': '“',
            'rdquo': '”',
            'bdquo': '„',
            'dagger': '†',
            'Dagger': '‡',
            'permil': '‰',
            'lsaquo': '‹',
            'rsaquo': '›',
            'euro': '€'
        };
        return str.replace(
            /&(nbsp|iexcl|cent|pound|curren|yen|brvbar|sect|uml|copy|ordf|laquo|not|shy|reg|macr|deg|plusmn|sup2|sup3|acute|micro|para|middot|cedil|sup1|ordm|raquo|frac14|frac12|frac34|iquest|Agrave|Aacute|Acirc|Atilde|Auml|Aring|AElig|Ccedil|Egrave|Eacute|Ecirc|Euml|Igrave|Iacute|Icirc|Iuml|ETH|Ntilde|Ograve|Oacute|Ocirc|Otilde|Ouml|times|Oslash|Ugrave|Uacute|Ucirc|Uuml|Yacute|THORN|szlig|agrave|aacute|acirc|atilde|auml|aring|aelig|ccedil|egrave|eacute|ecirc|euml|igrave|iacute|icirc|iuml|eth|ntilde|ograve|oacute|ocirc|otilde|ouml|divde|oslash|ugrave|uacute|ucirc|uuml|yacute|thorn|yuml|fnof|Alpha|Beta|Gamma|Delta|Epsilon|Zeta|Eta|Theta|Iota|Kappa|Lambda|Mu|Nu|Xi|	Omicron|Pi|Rho|Sigma|Tau|Upsilon|Phi|Chi|Psi|Omega|alpha|beta|gamma|delta|epsilon|zeta|eta|theta|iota|kappa|mu|xi|omicron|pi|rho|sigmaf|sigma|tau|upsilon|phi|chi|psi|omega|thetasym|upsih|piv|bull|hellip|prime|Prime|frasl|image|real|trade|alefsym|larr|uarr|rarr|darr|harr|crarr|uArr|rArr|dArr|hArr|forall|part|exist|empty|nabla|isin|notin|ni|prod|sum|minus|lowast|radic|prop|infin|ang|and|or|cap|cup|int|there4|sim|cong|asymp|ne|equiv|le|ge|sub|sup|nsub|sube|supe|oplus|otimes|perp|sdot|lceil|rceil|lfloor|rfloor|lang|rang|loz|spades|clubs|hearts|diams|quot|amp|lt|gt|OElig|oelig|Scaron|scaron|Yuml|circ|tilde|ensp|emsp|thinsp|zwnj|zwj|lrm|rlm|ndash|mdash|lsquo|rsquo|sbquo|ldquo|rdquo|bdquo|dagge|Dagger|permil|lsaquo|rsaquo|euro);/ig,
            function (all, t) {
                return arrEntities[t];
            });
    },

    // js转义字符（app端不支持）
    decodeHtml (html) {
        let txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value.replace(/<img/g,"<img style='width:100%;height:auto;'");
    },
}