<template>
  <div class="box">
    <!-- 面包屑 -->
    <div class="indexNews-nav">
      您的位置 :
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/testing' }"
          >趣味测评</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          testingInfo.cFormDetail.cFormName
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="indexNews-box">
      <div class="start" v-if="timeState == 'start'">
        <p class="box-item-title">{{ testingInfo.cFormDetail.cFormName }}</p>
        <p class="num">{{ testNum }}人测过</p>
        <p class="start-text" v-html="testingInfo.cFormDetail.descContent"></p>
        <div class="center">
          <div class="btn-start" @click="startAppraisal">开始测评</div>
        </div>
      </div>
      <div class="content" v-else-if="timeState == 'content'">
        <p class="box-item-title">{{ testingInfo.cFormDetail.cFormName }}</p>
        <div class="progress">
          <div>
            <el-progress
              :stroke-width="16"
              :percentage="(posIndex / percentageTotalNum) * 100"
              :format="progressFormat"
              :text-inside="true"
            ></el-progress>
          </div>
          <div class="text">
            {{ this.posIndex }}<span>/{{ this.percentageTotalNum }}</span>
          </div>
        </div>

        <div
          class="topic"
          v-for="(item, index) in testingInfo.cformQuestions"
          :key="index"
          v-show="posIndex == index + 1">
          <p class="topic-title">
            {{ item.questionName }}
          </p>
          <div class="radio" v-if="item.questionType == 1">
            <el-radio-group v-model="item.answer">
              <el-radio
                :label="item1.orderNum"
                v-for="item1 in item.fields"
                :key="item1.fieldId"
                :value="item1.fieldId"
                @change="getRadioValue(item1)"
              >
                {{ item1.itemTitle }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="checkbox" v-if="item.questionType == 2">
            <el-checkbox-group v-model="item.answer">
              <!-- <el-checkbox label="复选框 A"></el-checkbox> -->
              <el-checkbox
                :label="item1.orderNum"
                v-for="item1 in item.fields"
                :key="item1.orderNum"
              >
                {{ item1.itemTitle }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="btns">
            <div v-show="index != 0" @click="prevQuestion()">
              <button>上一题</button>
            </div>
            <div
              v-show="index != testingInfo.cformQuestions.length - 1"
              :class="{ disable: item.answer == undefined }"
              @click="nextQuestion()"
            >
              <button>下一题</button>
            </div>
            <div
              v-show="index == testingInfo.cformQuestions.length - 1"
              @click="topicSubmit()"
            >
              <button>提交</button>
            </div>
          </div>
        </div>
      </div>
      <div class="end" v-else-if="timeState == 'end'">
        <div class="endBox">
          <div class="end-img">
          <img src="../assets/fun/funOff.png" alt="">
        </div>
        <div class="end-title">测试结果</div>
        <div class="end-content">{{ this.tips }}</div>
        <button @click="againTest()">重新测试</button>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">111</div> -->
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="15%"
        style="top:30vh"
        center
        :before-close="handleClose">
        <span>请对作答本题</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false" >关 闭</el-button>
        </span>
    </el-dialog>

    <el-dialog
        title="错误提示"
        :visible.sync="dialogErr"
        width="15%"
        center
        :before-close="handleCloseErr">
        <span>{{ this.errMsg }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogErr = false">关 闭</el-button>
        </span>
    </el-dialog>

  </div>
</template>
<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
import { isEmpty } from "../utils/utils";
export default {
  data() {
    return {
      testingInfo: {
        cFormDetail: {},
        cformQuestions: [],
      },
      cFormDetail:{},
      cformQuestions:[],
      timeState: "start",
      posIndex: 1,
      percentageTotalNum: 10,
      userChoseDict :{},
      tips:'感谢您的参与',
      errMsg:"",
      dialogVisible: false,
      dialogErr:false,
      testNum:0
    };
  },
  computed: {},
  mounted() {
    this.getCformInfo();
    let siteConfig = getItem("centerConfig");
    document.title = this.testingInfo.title + " - " + siteConfig.centerName;
  },
  methods: {
    async getCformInfo() {
      let artId = this.$route.params.artId;
      let testNum = this.$route.query.testNum;
      this.testNum = testNum?testNum:0;
      get("/testing/cform", { artId: artId })
        .then((res) => {
          res.data.cformQuestions.forEach((item) => {
            if (item.questionType == 1) {
              item.answer = [];
            }
          });
          this.testingInfo = res.data;
          this.cFormDetail = res.data.cFormDetail;
          this.cformQuestions = res.data.cformQuestions
          this.percentageTotalNum = this.testingInfo.cformQuestions.length;
          let siteConfig = getItem("centerConfig");
          document.title =
            res.data.cFormDetail.cFormName + " - " + siteConfig.centerName;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startAppraisal() {
      this.timeState = "content";
    },
    progressFormat() {
      return "";
    },
    prevQuestion() {
      this.posIndex--;
    },
    getRadioValue(fieldInfo){
      let qid = fieldInfo.questionId;
      this.userChoseDict[qid] = fieldInfo.fieldId;
    },
    nextQuestion() {
      let currentPos = this.posIndex;
      let qid =  currentPos - 1;
      let qInfo = this.cformQuestions[qid];
      let checkSelected = this.userChoseDict[qInfo.questionId];
      if(isEmpty(checkSelected)){
        //弹框提示
        this.dialogVisible=true
      }else{
        this.posIndex++;
      }
    },
    topicSubmit() {
      let currentPos = this.posIndex;
      let qid =  currentPos - 1;
      let qInfo = this.cformQuestions[qid];
      let checkSelected = this.userChoseDict[qInfo.questionId];

      if(isEmpty(checkSelected)){
        //弹框提示
        this.dialogVisible=true
      }else{
        let Base64 = require('base-64');
        get("/testing/getresult", 
          { cformId: this.cFormDetail.cFormId, 
            submitData: encodeURI(Base64.encode(JSON.stringify(this.userChoseDict)))
          })
        .then((res) => {
          if (res.code == 0) {
            this.tips = res.data.tips
            this.timeState = "end";
          }else{
            this.errMsg = res.msg
            this.dialogErr=true
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
     handleClose(done) {
        this.dialogVisible=false
      },
     handleCloseErr(done){
        this.dialogErr=false
    },
    // 重新测试
    againTest(){
      // location.reload(); //强制刷新
      this.$router.go(0);
      // this.timeState = "content"; //返回到了答题页面，答题记录仍然存在
    },
  },
};
</script>
<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.box {
  background: #eff2fa;
  padding-bottom: 100px;
  min-height: 500px;
}
.indexNews-nav {
  width: 1080px;
  line-height: 60px;
  display: flex;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
  color: #6f7174;
}
.el-breadcrumb {
  line-height: 60px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  // color: #6F7174;
  color: #4676f8;
}
.indexNews-box {
  width: 1080px;
  // height: 1759px;
  background: #ffffff;
  //   border: 1px solid black;
  margin: 0px auto;
  padding: 60px 80px;
  box-sizing: border-box;
}
.box-item-title {
  line-height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  text-align: center;
}
.num {
  font-size: 12px;
  font-weight: 400;
  color: #9b9da2;
  line-height: 12px;
  text-align: center;
  margin: 11px 0 34px 0;
}
.start-text {
  font-size: 14px;
  font-weight: 400;
  color: #3b3d41;
  line-height: 22px;
  margin-bottom: 48px;
}
.btn-start {
  line-height: 40px;
  background: #4676f8;
  border-radius: 25px;
  padding: 0 48px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
}
.center {
  text-align: center;
}
.content .box-item-title {
  margin-bottom: 32px;
}
.progress {
  display: flex;
  width: 100%;
}
.progress div:nth-child(1) {
  width: 100%;
}
.progress div:nth-child(2) {
  white-space: nowrap;
  margin-left: 32px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
}
.progress div:nth-child(2) span {
  color: #9b9da2;
}
.el-radio-group,
.el-checkbox-group {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.el-radio,
.el-checkbox {
  padding-bottom: 20px;
}
.btns {
  display: flex;
  margin-top: 30px;
  padding-right: 20px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
button {
  cursor: pointer;
}
.topic-title {
  margin-top: 56px;
}

button {
  // border: none;
  border: 1px solid #4676f8;
  margin: 0 auto;
  width: 100px;
  height: 30px;
  background: white;
  color: #4676f8 !important;
  border-radius: 14px;
  margin-right: 64px;
}
button:hover {
  background: #4676f8;
  color: white !important;
}
/deep/.dialog-footer>button{
  line-height: 6px; 
  margin-right: 0px;
}
.end-title{
  width: 64px;
  height: 22px;
  font-size: 16px;
  margin-top: 24px;
  font-weight: 600;
  color: #3B3D41;
  line-height: 22px;
  margin: 24px auto 16px;
}
.end-img{
 margin-left: 310px;
}
.end-content{
  font-size: 14px;
  margin-bottom: 32px;
  font-weight: 400;
  color: #3B3D41;
  margin: 0 auto;
  text-align:center;
}

.endBox>button{
  margin: 32px 380px 59px;
  width: 160px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid #4676F8;
  }
</style>