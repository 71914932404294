<template>
  <div class="box">
    <!-- 面包屑 -->
    <div class="indexNews-nav">
      您的位置 :
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/${artInfo.catePath}` }">{{ artInfo.cateName }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ artInfo.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="indexNews-box">
      <div class="indexNews">
        <div class="indexNews-title">
          <h2>{{ artInfo.title }}</h2>
          <div class="time">{{ artInfo.createdAt }}</div>
        </div>
        <div class="border-bottom"></div>
        <!-- <div class="indexNews-img">
          <img v-bind:src="artInfo.imgUrl" />
        </div> -->
        <div class="indexNews-text indexNews-text2">
          <p class="box_img" v-html="artInfo.artBody"></p>
        </div>
        <!-- <div class="writings">
          <div class="up-writings">上一篇</div>
          <div class="writings-text">永远不要去责怪你生命中的任何一个人</div>
          <div class="dowm-writings">下一篇</div>
          <div class="writings-text">永远不要去责怪你生命中的任何一个人</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'
export default {
  data() {
    return {
      artInfo: {},
    }
  },
  created() {},
  computed: {},
  mounted() {
    this.getNewsInfo()
  },
  methods: {
    getNewsInfo() {
      let articleId = this.$route.params.artId
      get('/article/detail', { articleId: articleId })
        .then((res) => {
          console.log(res)
          this.artInfo = res.data;
          let siteConfig = getItem('centerConfig')
          document.title = this.artInfo.title + ' - ' + siteConfig.centerName
          this.artInfo.artBody = this.$escape.decodeHtml(res.data.artBody)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  updated() {
    window.scroll(0, 0)
  },
}
</script>
<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.box {
  background: #eff2fa;
  padding-bottom: 100px;
}
.indexNews-nav {
  width: 1080px;
  line-height: 60px;
  display: flex;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
  color: #6f7174;
}
.el-breadcrumb {
  line-height: 60px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  // color: #6F7174;
  color: #4676f8;
}
.indexNews-box {
  width: 1080px;
  // height: 1759px;
  background: #ffffff;
  margin: 0px auto;
  padding-top: 1px;
}
.indexNews {
  width: 920px;
  // height: 100%;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 40px;
  //   border: 1px solid black;
}
.indexNews-title > h2 {
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
}
.time {
  font-size: 12px;
  font-weight: 400;
  color: #9b9da2;
  margin-top: 10px;
  margin-bottom: 24px;
}
.border-bottom {
  width: 920px;
  height: 1px;
  background: #e5eaf1;
}
.indexNews-img > img {
  width: 920px;
  height: 338px;
  background: #d8d8d8;
  border-radius: 12px;
  margin-top: 32px;
  width: 80%;
}
// .indexNews-text {
//   margin-top: 48px;
//   padding-bottom: 48px;
//   width: 920px;
//   .box_img {
//     width: 100%;
//     // height: 500px;
//     overflow: hidden;
//     img {
//       width: 100%;
//       height: 100%;
//       object-fit: fill;
//     }
//   }
// }
.box_img > p > img {
  margin: 0 auto;
}
p {
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  line-height: 32px;
  text-indent: 2rem;
  width: 920px;
}
.writings {
  margin-top: 56px;
  padding-bottom: 56px;
}
.up-writings,
.dowm-writings {
  width: 36px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #9b9da2;
  line-height: 17px;
}
.writings-text {
  width: 238px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 16px;
}
.bottom {
  margin-top: 100px;
}
</style>

<style lang="less">
.box_img {
  img {
    max-width: 80%;
    // margin: 0 auto;
    // margin-left: 8%;
  }
}
.box_img > p > img {
  max-width: 90%;
  height: 100%;
}
.indexNews-text2 {
  margin-top: 48px;
  padding-bottom: 48px;
  width: 920px;
}
</style>