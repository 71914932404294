<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/central/img-top.png" alt="" />
    </div>
    <div class="center-box">
      <div class="center">
        <div class="center-nav">
          <div
            :class="{
              'center-nav-list': 'center-nav-list',
              'center-nav-list-active': tabsActive == 'centerinfo',
            }"
            @click="tabsActive = 'centerinfo'"
          >
            中心概况
            <!-- {{centerInfo.pageName}} -->
          </div>
          <div
            :class="{
              'center-nav-list': 'center-nav-list',
              'center-nav-list-active': tabsActive == 'teachers',
            }"
            @click="tabsActive = 'teachers'"
          >
            师资团队
          </div>
          <!-- <div
            :class="{
              'center-nav-list': 'center-nav-list',
              'center-nav-list-active': tabsActive == 'schedule',
            }"
            @click="tabsActive = 'schedule'"
          >
            值班表
          </div> -->
        </div>

        <div>
          <!-- 中心概况 -->
          <div class="centent1" v-if="tabsActive == 'centerinfo'">
            <!-- <div class="center-img"></div> -->
            <div class="article-box">
              <!-- <div class="article-title">{{ centerInfo.pageName }}</div> -->
              <div class="article-text" v-html="centerInfo.pageBody"></div>
            </div>
          </div>
          <div class="centent2" v-if="tabsActive == 'teachers'">
            <div class="article-box">
              <!-- 师资团队 -->
              <div>
                <!-- <div class="team-nav">
                  <div
                    :class="{
                      'team-nav-list': true,
                      tabActive: tabActive == 0,
                    }"
                    @click="tabChange(0)"
                  >
                    <span>全部</span>
                  </div>
                  <div
                    :class="{
                      'team-nav-list': true,
                      tabActive: tabActive == 1,
                    }"
                    @click="tabChange(1)"
                  >
                    专家
                  </div>
                  <div
                    :class="{
                      'team-nav-list': true,
                      tabActive: tabActive == 2,
                    }"
                    @click="tabChange(2)"
                  >
                    咨询师
                  </div>
                  <div
                    :class="{
                      'team-nav-list': true,
                      tabActive: tabActive == 3,
                    }"
                    @click="tabChange(3)"
                  >
                    特聘
                  </div> -->
              </div>
              <div class="teacherTeam-box" v-for="teacherTeamItem in teacherTeam" :key="teacherTeamItem.id">
                <div class="teacherTeam-img">
                  <div v-if="teacherTeamItem.teacherPhoto == ''"><img src="../assets/home/dafaultHead.png" alt="" /></div>
                  <div v-else><img v-bind:src="teacherTeamItem.teacherPhoto" /></div>
                </div>
                <div class="teacherTeam-text">
                  <div>
                    <div class="teacherTeam-teacher">
                      <span class="teacherName">{{ teacherTeamItem.teacherName }}</span>
                      <span class="teacherIntro">{{ teacherTeamItem.teacherTitle }}</span>
                    </div>
                    <div class="teacherTeam-text-info">
                      {{ teacherTeamItem.introduce }}
                    </div>
                    <div class="teacherTeam-text-info" v-if="teacherTeamItem.introduce == ''">暂无介绍</div>
                  </div>
                </div>
              </div>
              <!-- 分页 -->
              <el-pagination :page-size="pageSize" layout="prev, pager, next" :total="teacherTeamCopy.length" pager-count:7 @current-change="currentChange" :current-page="currentPage" v-if="teacherTeam.length"> </el-pagination>

              <div class="no-content" v-if="!teacherTeam.length">
                <img src="../assets/central/noContent.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="table" v-if="tabsActive == 'schedule'" >
            <div class="table-title">{{ schedule.pageName }}</div>
            <div class="table-text table-form" v-html="schedule.pageBody"></div>
            <div class="table-form"></div>
          </div> 
         </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'
import { Container } from 'element-ui'
export default {
  name: 'central',
  data() {
    return {
      total: 24,
      currentPage: 1, // 当前页码
      pageSize: 10, //每页显示条数
      teacherTeam: [],
      tabsActive: 'centerinfo',
      schedule: {},
      centerInfo: {},
      tabActive: 0,
      teachers: [],
      teacherTeamCopy: [],
    }
  },

  methods: {
    async getInfo() {
      get('/index/center')
        .then((res) => {
          console.log(res)
          this.teacherTeamCopy = res.data.teachers
          this.teacherTeam = this.teacherTeamCopy.slice(0, 10)
          this.schedule = res.data.schedule
          this.centerInfo = res.data.centerInfo
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 分页
    currentChange(val) {
      console.log('切换后的页码', val)
      this.currentPage = val
      let vall = val - 1
      this.teacherTeam = this.teacherTeamCopy.slice(vall * 10, vall * 10 + 10)
    },
    tabChange(type) {
      this.tabActive = type
      get('/teacher/lists', { type: type })
        .then((res) => {
          console.log(res)
          this.teacherTeamCopy = res.data
          this.currentChange(1)
          console.log(this.teacherTeamCopy)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    tabFilter(list) {
      if (this.tabActive == 0) return list
      return list.filter((item) => {
        return item.cateId == this.tabActive
      })
    },
  },
  mounted() {
    let pageId = this.$route.query.pageId ? this.$route.query.pageId : 'centerinfo'
    this.tabsActive = pageId
    this.getInfo()
    let siteConfig = getItem('centerConfig')
    document.title = '中心概况 - ' + siteConfig.centerName
  },
  updated() {
    window.scroll(0, 0)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
// 主体部分
.no-content {
  margin-left: 350px;
}
.topImg > img {
  width: 100%;
  display: block;
}
.center-box {
  width: 100%;
  background: #eff2fa;
  padding-bottom: 100px;
}
.center {
  width: 1080px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin: 0 auto;
}
.center-nav {
  display: flex;
  // padding-left: 385px;
  padding-left: 430px;
  margin: 60px 0px;
}
.center-nav-list {
  font-size: 18px;
  font-weight: 400;
  padding-right: 56px;
  color: #9b9da2;
  cursor: pointer;
}
.center-nav-list:hover {
  // width: 72px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 25px;
}
.center-img {
  width: 920px;
  height: 460px;
  border-radius: 20px;
  margin: 0 auto;
}
.article-box {
  width: 920px;
  min-height: 350px;
  margin: 0 auto;
  margin-top: 48px;
}
.article-title {
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  width: 252px;
  height: 25px;
  margin: 0 auto;
  padding-bottom: 24px;
  text-align: center;
}

.article-text {
  font-size: 14px;
  font-weight: 400;
  color: #3b3d41;
}

// 点击样式
.center-nav-list-active {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 25px;
}
.team-nav-list.tabActive {
  color: #4676f8;
  border-radius: 14px;
  box-shadow: 0 0 0 1px #4676f8;
  background: rgba(70, 118, 248, 0.05);
  box-sizing: border-box;
}

//师资团队

// 导航
.team-nav {
  padding-bottom: 40px;
  display: flex;
  height: 26px;
  line-height: 26px;
}
.team {
  width: 1080px;
  margin: 0 auto;
}
.team-nav-all {
  width: 64px;
  height: 26px;
  border-radius: 14px;
  line-height: 26px;
  text-align: center;
}
.team-nav-all > span {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}
.team-nav-list {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  text-align: center;
}
.team-nav-list:hover {
  color: #4676f8;
  cursor: pointer;
}

// 师资团队教授
.mindArticle {
  margin-top: 32px;
}
.mind-article-list {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #e5eaf1;
}

.mind-article-more {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
  line-height: 20px;
}
.mind-article-list-img {
  width: 220px;
  height: 124px;
  border-radius: 6px;
}
.mind-article-list-text {
  margin-left: 24px;
  width: 406px;
  height: 124px;
}
.mind-article-title {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mind-article-info {
  margin-top: 12px;
  width: 406px;
  font-size: 14px;
  color: #9b9da2;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: initial;
}
.mindArticleBorder-bottom {
  width: 100%;
  height: 1px;
  background: #e5eaf1;
  margin: 16px 0;
}
.teacherTeam-box {
  width: 100%;
  // height: 168px;
  padding: 16px 0;
  display: flex;
  border-bottom: 1px solid #e5eaf1;
}
.teacherTeam-box:nth-last-child(2) {
  border-bottom: none;
}

.teacherTeam-img,
.teacherTeam-text {
  display: flex;
  align-items: center;
}
.teacherTeam-img > div > img {
  width: 120px;
  height: 120px;
  // margin-top: 22px;
  border-radius: 50%;
}
.teacherName {
  width: 45px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
}
.teacherIntro {
  width: 26px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
  line-height: 20px;
  padding-left: 12px;
}
.teacherTeam-text {
  margin-left: 24px;
  width: 506px;
}
.teacherTeam-text-info {
  width: 700px;
  // height: 60px;
  font-size: 14px;
  font-weight: 400;
  color: #3b3d41;
  line-height: 20px;
  margin-top: 12px;
  // display: flex;
  align-items: center;
}
//值班表
.table {
  width: 920px;
  height: 570px;
  margin: 0 auto;
}
.table-title {
  width: 70px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 25px;
  margin: 0 auto;
}
.table-text {
  width: 356px;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  // margin: 27px auto;
  margin: 0 -10px;
  // margin: 0 auto;
}

.table-form {
  width: 920px;
}
// 分页样式
.el-pagination {
  padding-left: 70%;
  padding-bottom: 20px;
}
</style>

<style >
.article-text > p > img {
  width: 920px;
  /* height: 200px; */
}
.table-text > p > img {
  width: 920px;
}
</style>