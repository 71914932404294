<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/course/img-top.png" alt="" />
    </div>
    <div class="course-box">
      <div class="course">
        <div class="course-nav">
          <div :class="{ 'course-nav-list': true, tabActive: tabActive == cateDataItem.cateId }"
            v-for="cateDataItem in cateData" :key="cateDataItem.cateId" @click="tabChange(cateDataItem.cateId)">{{
              cateDataItem.cateName
            }}</div>
          <!-- <div class="course-nav-list">公共课</div> -->
        </div>
        <!-- 课程主体部分 -->
        <div class="learnCourse">
          <div class="learnCourse-list hoverLearnCourse hover-item hover-item-bigImg"
            v-for="cateNavDataItem in cataDataList" :key="cateNavDataItem.id" @click="toClasses(cateNavDataItem)">
            <div class="learnCourse-list-info">
              <div class="learnCourse-list-info-img">
                <img v-bind:src="cateNavDataItem.coverImg" />
              </div>
              <div class="learnCourse-list-info-title hover-item-title">{{ cateNavDataItem.courseTitle }}</div>
              <div class="learnCourse-list-info-hot">
                <img src="../assets/home/leftFour-icon.png" alt="">
                <span class="learnCourse-list-info-hot">{{ cateNavDataItem.views }}</span>
              </div>
            </div>
          </div>
          <div class="no-content" v-if="!cataDataList.length">
            <img src="../assets/home/noContent.png" alt="">
          </div>
        </div>
        <!-- 分页 -->
        <div v-if="cataDataList.length !== 0">
          <el-pagination :page-size="9" background layout="prev, pager, next" :total="total" pager-count:7
            @current-change="currentChange" :current-page="currentPage"> </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Container } from 'element-ui';
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
import request from "../utils/request";

export default {
  name: "course",
  data () {
    return {
      cateData: [],
      cataDataList: [],
      tabActive: 0,
      cateId: 0,
      total: 0,
      page: 1,
      currentPage: 1, // 当前页码
      zyUrl: ''
    };
  },
  methods: {
    // 分页
    currentChange (val) {
      this.currentPage = val
      window.scrollTo(0, 0)
      this.getCourseList()
    },
    // 请求课程学习栏目
    async getCourseNav () {
      // get("/course/cates").then((res) => {
      //   this.cateData = res.data
      // }).catch(err => {
      //   console.log(err)
      // })
      //课程分类
      let data = {};
      request({
        url: "/course/cate",
        method: "post",
        data,
      }).then((res) => {
        this.cateData = res.data;
        this.cateData.unshift({ cateId: 0, cateName: '全部' })
      });
    },
    //课程列表
    async getCourseList (cateId) {
      // get("/course/lists", { cateId: this.cateId, page: this.currentPage, count: 1 }).then((res) => {
      //   this.cataDataList = res.data.lists
      //   this.total = res.data.total
      // }).catch(err => {
      //   console.log(err)
      // })
      let data = {
        cateId: this.cateId,
        pageNum: this.currentPage,
        count: 1,
      };
      request({
        url: "/course/lists",
        method: "post",
        data: data,
      }).then((res) => {
        this.cataDataList = res.data.lists;
        this.total = res.data.count;
      })
    },
    tabChange (cateId) {
      this.cateId = cateId
      this.currentPage = 1
      this.getCourseList(cateId)
      this.tabActive = cateId
    },
    tabFilter (list) {
      if (this.tabActive == 0) return list
      return list.filter(item => {
        return item.cateId == this.tabActive
      })
    },

    toClasses (e) {
      console.log(e)
      window.open(`https://res.x-lingtong.cn/courseDetail/${e.courseId}`, '_blank');
    }
  },
  mounted () {
    let { zyUrl } = projectConfig
    this.zyUrl = zyUrl
    this.getCourseNav();
    this.getCourseList();
    let siteConfig = getItem('centerConfig')
    document.title = '课程学习 - ' + siteConfig.centerName;

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

// 主体部分
.no-content {
  margin: 0 auto;
  text-align: center;
}

.topImg>img {
  width: 100%;
  display: block; //解决图片下1像素bug
}

.course-box {
  background: #EFF2FA;
}

.course-nav {
  padding-top: 40px;
  padding-bottom: 56px;
  display: flex;
}

.course {
  width: 1080px;
  margin: 0 auto
}

.course-nav-all {
  width: 64px;
  height: 22px;
  // background: rgba(70, 118, 248, 0.05);
  border-radius: 14px;
  cursor: pointer;
}

.course-nav-all>span {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 14px;
  // margin-right: 24px;
}

.course-nav-list {
  cursor: pointer;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3B3D41;
  text-align: center;
  margin: 0 12px;
  // border: 1px solid #4676F8;
}

.course-nav-list:hover {
  color: #4676F8;
}

.tabActive {
  color: #4676F8;
  border-radius: 14px;
  box-shadow: 0 0 0 1px #4676F8;
}

.learnCourse {
  display: flex;
  flex-wrap: wrap;
  // padding-bottom: 144px;
}

.learnCourse-list {
  width: 338px;
  height: 273px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;
  position: relative;
  cursor: pointer;
}

.learnCourse-list:nth-child(3n) {
  margin-right: 0;
}

.hoverLearnCourse:hover {
  width: 338px;
  height: 273px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.hover-item:hover .hover-item-title {
  color: #4676f8;
}

.hover-item-bigImg:hover {
  transform: scale(1.02);
}

.learnCourse-list-info {
  width: 306px;
  height: 240px;
  // border: 1px solid black;
}

.learnCourse-list-info-img>img {
  width: 306px;
  height: 172px;
  background: #D8D8D8;
  border-radius: 6px;
}

.learnCourse-list-info-title {
  font-size: 16px;
  font-weight: 600;
  color: #3B3D41;
  padding-top: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.learnCourse-list-info-hot {
  padding-top: 12px;
  font-size: 12px;
  color: #9B9DA2;
}

.learnCourse-list-info-hot>img {
  width: 16px;
  height: 18px;
  padding-right: 3px;
  vertical-align: middle;
}

// 分页
.el-pagination {
  text-align: center;
  padding: 25px 0px 50px;
}
</style>
