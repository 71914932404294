<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/fun/img-top.png" alt="" />
    </div>
    <div class="fun-box">
     <div class="fun">
        <div class="fun-nav">
        <div :class="{'fun-nav-all':true , tabActive:tabActive==cateInfo.cateId}" @click="tabChange(cateInfo.cateId)">全部</div>
        <div :class="{'fun-nav-all':true , tabActive:tabActive == artNavItem.cateId}"
         v-for="artNavItem in artNav" :key="artNavItem.cateId"
         @click="tabChange(artNavItem.cateId)"
         >{{ artNavItem.cateName }}</div>
      </div>
      <!-- 量表 -->
      <div class="scale">
        <div class="scale-list hover-item hoverScaleList" v-for="artListItem in artList" 
        :key="artListItem.id" @click="scaleClick(artListItem.id,artListItem.testNum)">
          <div class="scale-list-info">
            <div class="scale-list-info-title hover-item-title">{{ artListItem. title}}</div>
            <div class="scale-list-info-looking">
                <img src="../assets/fun/fun-icon.png" alt="">
                <span  class="scale-list-info-looking"> {{ artListItem.testNum}}人测过</span>
            </div>
            <div class="scale-list-info-text">
              {{ artListItem. summary}}
            </div>
          </div>
        </div>
        <div class="no-content" v-if="!artList.length">
          <img src="../assets/home/noContent.png" alt="">
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
          :page-size="4"
          :page-sizes=[4,6,8]
          size-change
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          :current-page="currentPage"
          v-if="artList.length"
          >
      </el-pagination>
     </div>
    </div>
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
export default {
  name: "HelloWorld",
  data() {
    return {
      artNav:[],
      cateInfo: {},
      artList:[],
      tabActive:0,
      currentPage: 1, // 当前页码
      total:null
    };
  },
  methods: {
    currentChange(val) {
      console.log("切换后的页码", val);
      this.currentPage = val
      this.getNewsList()
    },
    // 请求文章分类
    async getNewsNav() {
      let cateName = this.$route.name;
      get("/index/subcates",{'cateName':cateName})
        .then((res) => {
          console.log(res);
          this.artNav=res.data.subCates
          this.cateInfo=res.data.cateInfo
          this.tabActive = res.data.cateInfo.cateId
          this.getNewsList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 请求文章列表
    async getNewsList() {
      console.log(this.$route)
      let cateId = this.$route.id;
      console.log(this.tabActive)
      // let params = { 'cateName':cateName }
      let params = { 'cateId': this.tabActive, 'page': this.currentPage}
      get("/article/lists", params)
        .then((res) => {
          this.artList = res.data.artList;
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tabChange(index){
      this.tabActive = index
      this.getNewsList()
    },
    scaleClick(id,testNum){
      this.$router.push(`testingDeail/${id}?testNum=` + testNum)
    }
  },
   mounted() {
    this.getNewsNav();
    let siteConfig = getItem('centerConfig')
    document.title = '趣味测评 - ' + siteConfig.centerName;
  },
  updated() { 
    window.scroll(0, 0); 
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.active-text {
  color: blue;
}

// 主体部分
.topImg > img {
  width: 100%;
  display: block; //解决图片下1像素bug
}

.fun-box{
  background: #EFF2FA;
}
.fun-nav{
  padding-top: 40px;
  padding-bottom: 56px;
  display: flex;
  align-items: center;
}
.fun{
  width: 1080px;
  margin: 0 auto

}
.fun-nav-all{
  width: 90px;
  height: 22px;
  // background: rgba(70, 118, 248, 0.05);
  border-radius: 14px;
  line-height: 22px;
  // margin-right: 0 12px;
  padding: 0 12px;
  cursor: pointer;
  margin: 0 12px;
  box-sizing: border-box;
  text-align: center;
}
.fun-nav-all>span{
  font-size: 16px;
  font-weight: 400;
  // color: #4676F8;
  line-height: 16px;
  // padding-left: 16px;
  
}
.fun-nav-list{
  padding-right: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #3B3D41;
  text-align: center;
  border: 1px solid #4676F8;
}
.fun-nav-list:hover{
  color: #4676F8;
}
.tabActive{
  color: #4676F8;
  border-radius: 14px;
  box-shadow: 0 0 0 1px #4676F8;
  // line-height: 20px;
  // line-height: 22px;
  // padding: 0 12px;
}
.scale{
  display: flex;
  flex-wrap: wrap;
  // height: 890px;
  justify-content: space-between;
  padding-bottom: 136px;
}
.scale-list{
  width: 520px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 40px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.hoverScaleList:hover{
  width: 520px;
  height: 180px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 12px;
  margin-bottom: 40px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.scale-list-color:hover{
  color: #4676F8;
}
.scale-list-info{
  width: 472px;
  // height: 156px;
  align-items: center;
  justify-content: center;
}
.scale-list-info-title{
  font-size: 16px;
  font-weight: 600;
  color: #3B3D41;
}
.scale-list-info-looking{
  font-size: 12px;
  font-weight: 400;
  color: #9B9DA2;
  padding-top: 14px;
  padding-bottom: 18px;
}
.scale-list-info-looking>img{
  width: 12px;
  height: 12px;
  padding-right: 8px;
}
.scale-list-info-text{
  font-size: 14px;
  font-weight: 400;
  color: #6F7174;
   display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: initial;
}
.hover-item:hover .hover-item-title {
  color: #4676f8;
}
.no-content{
  margin: 0 auto;
}
// 分页样式
.el-pagination {
  padding-left: 80%;
  padding-bottom: 20px;
}
// /deep/.el-pagination button:disabled{
//   background-color: #EFF2FA !important;
// }
/deep/.el-pagination button{
  background-color: #EFF2FA !important;
}
/deep/.el-pager li.number{
  background-color: #EFF2FA !important;
}
</style>
