<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    // if (
    //   /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) == true
    // ) {
    //   // 当前是pc端，跳转到移动端
    //   window.location.href = "https://m.x-lingtong.com/index";
    // }

    console.log(this.$device)
    if (this.$device.mobile) {
      window.location.href = 'https://xltm.x-lingtong.com'
    }
  },
}
</script>
<style lang="less">
body,
html {
  padding: 0;
  margin: 0;
}
#app {
  min-width: 1264px;
}
</style>
