import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import device from "vue-device-detector"
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(device);

//特殊字符转换
import escape from './utils/escape'
Vue.prototype.$escape = escape

Vue.config.productionTip = false

// import Artplayer from 'artplayer';
// const art = new Artplayer({
//     container: '.artplayer-app',
//     url: '/assets/sample/video.mp4',
// });

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

