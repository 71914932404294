<template>
  <div class="box">
    <!-- 轮播部分 -->
    <!-- <div class="banner">
      <el-carousel :interval="5000" arrow="always" height="680px">
        <el-carousel-item v-for="bannerItem in banners" :key="bannerItem.index">
          <div>
            <img v-bind:src="bannerItem.imgUrl" style="width: 100%; height: 680px" />
            <img v-bind:src="bannerItem.imgUrl" style="width: 100%" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <div class="banner">
      <el-carousel :interval="3000" arrow="never">
        <el-carousel-item v-for="bannerItem in banners" :key="bannerItem.id" height="100%">
          <img :src="bannerItem.imgUrl" alt="" style="width: 100%;height: 100%;" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 中心新闻内容 -->
    <div class="center">
      <!-- 左边部分 -->
      <div class="left">
        <div class="inside-box">
          <div class="newsTitleInfo-box">
            <div class="newsTitle">
              <div class="news">
                <span class="news"><img class="tb" src="../assets/home/born-icon.png" alt="" />
                  <span class="headline">新闻公告</span>
                </span>
              </div>
              <div><span class="seeMore" @click="toRouter('/news')">查看更多</span></div>
            </div>
            <div class="newsInfo">
              <div class="newsInfo-item hover-item hover-item-shadow" v-for="(newsItem, index) in newsList"
                :key="newsItem.id" @click="toRouterList(`/newsDetial/${newsItem.id}`)">
                <div class="newsH2">
                  <el-tag class="news-icon" v-if="'新闻公告'">{{ newsItem.cateName }}</el-tag>

                  <h2 class="hover-item-title">{{ newsItem.title }}</h2>
                </div>
                <div class="news-notice">
                  <p :class="{ newsNow: index != 0 }">
                    {{ newsItem.summary }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 热门课程   public公用的样式-->
          <div class="newsTitle laughably-public">
            <div class="news">
              <span class="news"><img class="tb" src="../assets/home/leftTwo-icon.png" alt="" />
                <span class="headline">热门课程</span>
              </span>
            </div>
            <div><span class="seeMore" @click="toRouter('/course')">查看更多</span></div>
          </div>
          <!-- 课程列表 -->
          <div class="course">
            <div class="course-list hover-item hover-item-shadow hover-item-bigImg" v-for="courseListItem in courseList"
              :key="courseListItem.id" @click="toClasses(courseListItem)">
              <div class="course-list-img hover-item-img">
                <img v-bind:src="courseListItem.coverImg" />
              </div>
              <h4 class="hover-item-title">
                {{ courseListItem.courseTitle }}
              </h4>
              <div>
                <span>
                  <img src="../assets/home/leftFour-icon.png" alt="" />
                </span>
                <span class="hot">{{ courseListItem.views }}</span>
              </div>
            </div>
          </div>
          <!-- 心理美文 -->
          <div>
            <div class="mind-box">
              <div class="mind-article">
                <span><img class="tb" src="../assets/home/leftFive-icon.png" alt="" />
                  <span class="headline">心理美文</span>
                </span>
              </div>
              <div class="mind-article-more" @click="toRouter('/psyprose')">查看更多</div>
            </div>
            <div class="mindArticle">
              <div class="mind-article-list hover-item-shadow hover-item" v-for="psyproseArtsItem in psyproseArts"
                :key="psyproseArtsItem.id" @click="toRouterList(`/newsDetial/${psyproseArtsItem.id}`)">
                <div class="mind-article-list-img">
                  <img v-bind:src="psyproseArtsItem.imgUrl" />
                </div>
                <div class="mind-article-list-text">
                  <div>
                    <div class="mind-article-title hover-item-title">
                      {{ psyproseArtsItem.title }}
                    </div>
                    <div class="mind-article-info">
                      {{ psyproseArtsItem.summary }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 师资团队 -->
          <div>
            <div class="mind-box">
              <div class="mind-article">
                <span><img class="tb" src="../assets/home/leftSix-icon.png" alt="" />
                  <span class="headline">师资团队</span>
                </span>
              </div>
              <div class="mind-article-more" @click="toRouter(`/center?pageId=teachers`)">查看更多</div>
            </div>

            <div class="teacherTeam-box" v-for="item in teachers" :key="item.id">
              <div class="teacherTeam-img">
                <div v-if="item.teacherPhoto == ''"><img src="../assets//home/dafaultHead.png" alt="" /></div>
                <div v-else><img v-bind:src="item.teacherPhoto" /></div>
              </div>
              <div class="teacherTeam-text">
                <div>
                  <div class="teacherTeam-teacher">
                    <span class="teacherName"> {{ item.teacherName }} </span>
                    <span class="teacherIntro">{{ item.teacherTitle }}</span>
                  </div>
                  <div class="teacherTeam-text-info">
                    {{ item.introduce }}
                  </div>
                  <div v-if="item.introduce == ''">暂无介绍</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右边部分 -->
      <div class="right">
        <div v-if="activitys.length">
          <div class="right-title">
            <div>
              <img src="../assets/home/activity-icon.png" alt="" />
              <span class="headline">产品介绍</span>
            </div>
            <div class="seeMore" @click="toRouter('/acenter')">查看更多</div>
          </div>
          <!-- 最新活动图 -->
          <div class="newActivitiesBox">
            <div class="newActivities">
              <img v-bind:src="activitys[0].imgUrl" @click="toActivitiesList(activitys[0].id)" />
            </div>
            <div class="newActivities-text" @click="toActivitiesList(activitys[0].id)">
              {{ activitys[0].title }}
            </div>
          </div>
        </div>
        <div class="btn-list">
          <div class="btn-list-top">
            <router-link :to="{
        path: '/indexNom',
        query: { pageName: 'personalconsultation' },
      }">
              <img class="btn-list-info" src="../assets/home/right-nav-one.png" alt="" />
            </router-link>
            <router-link :to="{
        path: '/indexNom',
        query: { pageName: 'groupconsultation' },
      }">
              <img class="btn-list-info" src="../assets/home/right-nav-two.png" alt="" />
            </router-link>
          </div>
          <div class="btn-list-bottom">
            <router-link :to="{ path: '/index' }">
              <img class="btn-list-info" src="../assets/home/right-nav-three.png" alt="" />
            </router-link>
            <img class="btn-list-info" @click="dialogFormVisible = true" src="../assets/home/right-nav-four.png"
              alt="" />
          </div>
        </div>
        <!-- <div class="img-roster">
          <img src="../assets/home/roster.png" alt="" @click="toTable()" />
        </div> -->
        <!-- 趣味测评 -->
        <div v-if="testing == []">
          <div class="right-title laughably-public">
            <div class="mind-article">
              <span><img class="tb" src="../assets/home/rightTwo-icon.png" alt="" />
                <span class="headline">趣味测评</span>
              </span>
            </div>
            <div class="seeMore" @click="toRouter('/testing')">查看更多</div>
          </div>
          <div class="laughably-info">
            <div class="laughably-list" v-for="testingItem in testing" :key="testingItem.id"
              @click="toRouterList(`/testingDeail/${testingItem.id}`)">
              {{ testingItem.title }}
            </div>
          </div>
        </div>
        <!-- 心理自助 -->
        <div class="right-title laughably-public">
          <div class="mind-article">
            <span><img class="tb" src="../assets/home/rightThree-icon.png" alt="" />
              <span class="headline">心理微视频</span>
            </span>
          </div>

          <div class="seeMore" @click="toRouter('/psyhelp')">查看更多</div>
        </div>
        <div class="mindSmallBox">
          <!-- 音频 -->
          <div class="mind-img hover-item" v-for="mediaAudioListItem in mediaAudioList" :key="mediaAudioListItem.id"
            @click="toAudioClass(mediaAudioListItem)">
            <div class="mind-img-list hover-item-img">
              <img v-bind:src="mediaAudioListItem.coverImg" />
            </div>
            <span class="mine-text-list hover-item-title">{{ mediaAudioListItem.title }}</span>
          </div>
          <!-- 视频 -->
          <!-- <div class="mineBigImgBox hover-item" v-for="mediaVideoListItem in mediaVideoList"
            :key="mediaVideoListItem.id" @click="toVideoClass(mediaVideoListItem.playUrl)">
            <div class="bigImg">
              <img v-bind:src="mediaVideoListItem.indexImgUrl" />
            </div>
            <span class="mine-text-list bigImg-text hover-item-title">{{ mediaVideoListItem.subject }}</span>
          </div> -->
        </div>
        <!-- 案列分享 -->
        <div class="right-title laughably-public">
          <div class="mind-article">
            <span><img class="tb" src="../assets/home/rightFour-icon.png" alt="" />
              <span class="headline">案例分享</span>
            </span>
          </div>
          <div class="seeMore" @click="toRouter('/cases')">查看更多</div>
        </div>
        <div class="case">
          <div class="case-list case-list-active" v-for="casesItem in cases" :key="casesItem.id"
            @click="toRouterList(`/newsDetial/${casesItem.id}`)">
            {{ casesItem.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog title="心理健康服务中心意见箱" :visible.sync="dialogFormVisible" class="dialogBox" center width="35%">
      <el-form :model="form" class="dialogList" ref="dialogList">
        <el-form-item label="姓名：" :label-width="formLabelWidth" :rules="[
        { required: true, message: '请输入姓名', trigger: 'blur' },
        {
          min: 2,
          max: 18,
          message: '长度在 2 到 18 个字符',
          trigger: ['blur', 'change'],
        },
      ]" prop="name">
          <el-input v-model="form.name" autocomplete="off" size="small"></el-input>
        </el-form-item>
        <el-form-item label="电话：" :rules="[
        { required: true, message: '请输入电话', trigger: 'blur' },
        {
          pattern: /^1[3|5|7|8|9]\d{9}$/,
          message: '请输入正确的手机号码格式',
          trigger: ['blur', 'change'],
        },
      ]" prop="contactPhone" :label-width="formLabelWidth">
          <el-input v-model.number="form.contactPhone" autocomplete="off" size="small" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="来信正文：" :rules="[
        {
          required: true,
          message: '请输入内容',
          trigger: ['blur', 'change'],
        },
      ]" prop="body" :label-width="formLabelWidth">
          <!-- <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea"> </el-input> -->
          <el-input type="textarea" v-model="form.body" placeholder="请输入内容" :rows="4" maxlength="1000" show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 意见箱提交完成 -->
    <!-- <el-dialog
      title="提交成功"
      center
      :visible.sync="closeDialog"
      width="30%"
      :before-close="closeDialog"
      top="30vh"
    >
      <span>感谢您的提交，我们已经收到了~</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog = false">关闭</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'
import request from "../utils/request";
export default {
  components: {},
  name: 'HelloIndex',
  data () {
    return {
      // showLen:3,
      // psyproseArts:[],
      closeDialog: false,
      textarea: '',
      cateList: [],
      centerConfig: {},
      teachers: [],
      newsList: [],
      testing: [],
      cases: [],
      courseList: [],
      psyproseArts: [],
      banners: [],
      pages: {},
      activitys: [],
      mediaAudioList: [],
      mediaVideoList: [],

      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: '',
        contactPhone: '',
        body: '',
        email: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      formLabelWidth: '100px',
    }
  },
  methods: {
    toActivitiesList (newsId) {
      this.$router.push({ path: `/newsDetial/${newsId}` })
    },
    getCourseList () {
      let data = {
        cateId: 0,
        pageNum: 1,
        count: 6,
      };
      request({
        url: "/course/lists",
        method: "post",
        data: data,
      }).then((res) => {
        if (res.data.lists && res.data.lists.length > 6) {
          this.courseList = res.data.lists.slice(0, 6)
        } else {
          this.courseList = res.data.lists || []
        }
      })
    },

    getList () {
      get('/index/common')
        .then((res) => {
          console.log(res)
          this.cateList = res.data.cateList
          this.teachers = res.data.teachers
          this.centerConfig = res.data.centerConfig
          this.newsList = res.data.newsList
          this.testing = res.data.testing
          this.cases = res.data.cases
          // this.courseList = res.data.courseList
          this.psyproseArts = res.data.psyproseArts
          this.banners = res.data.banners
          this.pages = res.data.pages
          this.activitys = res.data.activitys
        })
        .catch((err) => { })
    },

    // 跳转封装
    toRouter (path) {
      this.$router.push(path)
    },
    toRouterList (path, id) {
      this.$router.push(path, id)
    },
    toTable () {
      //去值班表页面
      this.$router.push({ path: `/center?pageId=schedule` })
    },
    // toFunList(id) {
    //   this.$router.push({ path: `/testingDeail/${id}` })
    // },
    // 点击弹出框确认
    submit () {
      this.$refs['dialogList'].validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false
          if (this.form.name == '') {
            this.dialogFormVisible = true
          } else {
            let name = this.form.name
            let contactPhone = this.form.contactPhone
            let body = this.form.body
            console.log(name)
            get('/misc/feedback', {
              name: name,
              contactPhone: contactPhone,
              body: body,
            })
              .then((res) => {
                console.log(res)
                this.dialogFormVisible = false
                this.form.name = ''
                this.form.contactPhone = ''
                this.form.body = ''
                this.closeDialog = true
              })
              .catch((err) => {
                console.log(err)
              })
          }
        } else {
          // alert("请补全信息")
          return false
        }
      })
    },
    // 点击弹出框取消
    cancel () {
      this.dialogFormVisible = false
      this.form.name = ''
      this.form.contactPhone = ''
      this.form.body = ''
    },
    toClasses (e) {
      //  window.location.href = e   //在当前窗口跳转
      window.open(`https://res.x-lingtong.cn/courseDetail/${e.courseId}`, '_blank');
    },
    toAudioClass (audio) {
      this.$router.push({ path: `/videoPlay/${audio.vid}` })
    },
    toVideoClass (video) {
      console.log(video);
      // this.$router.push({ path: `/videoPlay/${id}` })
      window.open(video, '_blank')
    },
    getClassList (cateId) { // 请求心理自主列表
      let data = {
        cateId: 0,
        pageNum: 1,
        count: 6,
      };
      request({
        url: "/smallvideo/lists",
        method: "post",
        data: data,
      }).then((res) => {
        if (res.data.list && res.data.list.length > 6) {
          this.mediaAudioList = res.data.list.slice(0, 6)
        } else {
          this.mediaAudioList = res.data.list || []
        }
      })
    },
  },

  mounted () {
    this.getList();
    this.getClassList();
    this.getCourseList()
    let siteConfig = getItem('centerConfig')
    document.title = siteConfig.centerName
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

// 弹框
.el-button {
  margin-right: 20px;
}

/deep/.el-input--small .el-input__inner {
  width: 45% !important;
}



// 组件轮播样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  height: 100%;
  line-height: 50%;
  margin: 0;
}

/deep/.el-carousel__arrow {
  margin: 0px 10% !important;
  width: 56px;
  height: 56px;
}

/deep/[class^='el-icon-'] {
  font-size: 25px;
}

/deep/.el-carousel__container>button {
  background-color: #ffffff !important;
  opacity: 0.2;
}

/deep/.el-carousel__container>button:hover {
  opacity: 0.7;
}

/deep/.el-icon-arrow-right {
  color: #9b9da2 !important;
}

/deep/.el-icon-arrow-left {
  color: #9b9da2 !important;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-carousel__item {
  transform: none !important;
}

.banner>img {
  height: 680px;
  background: rgba(3, 12, 37, 0.16);
  position: relative;
}

.el-carousel>img {
  height: 680px !important;
  transform: none !important;
}

/deep/.el-carousel__container {
  height: 630px;
  // height: 520px;
}


// 轮播
// 媒体查询 分辨率 900 - 1440
@media screen and (min-width: 900px) and (max-width: 1700px) {
  /deep/.el-carousel__item {
    height: 520px !important;
  }

  /deep/.el-carousel__item>img {
    height: 520px !important;
  }

  /deep/.el-carousel__container {
    height: 520px !important;
  }

  /deep/.el-carousel__container>img {
    height: 520px !important;
  }

  /deep/.class-list {
    margin-top: 50px !important;
  }

  .block {
    height: 620px;
  }

  .classZiYuan {
    height: 570px !important;
  }
}

.center {
  width: 1098px;
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.center>.left {
  width: 682px;
}

.center>.right {
  width: 342px;
  height: 100%;
  margin-left: 72px;
}

.newsTitle {
  display: flex;
  justify-content: space-between;
  height: 25px;
  line-height: 25px;
}

.news {
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  height: 25px;
  line-height: 25px;
}

.tb {
  height: 25px;
  line-height: 25px;
  margin-top: -5px;
  margin-right: 1px;
}

img {
  vertical-align: middle;
}

.seeMore {
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
  cursor: pointer;
}

.newsH2 {
  display: flex;
  height: 28px;
}

.newsH3 {
  display: flex;
  height: 28px;
}

.news-icon {
  width: 40px;
  height: 22px;
}

h2 {
  font-weight: 600;
  color: #020a1b;
  font-size: 20px;
  line-height: 22px;
}

h3 {
  width: 598px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p {
  font-size: 14px;
  color: #9b9da2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 48px;
}

.news-notice {
  width: 650px;
  padding-top: 12px;
  font-size: 14px;
  color: #9b9da2;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 政策文件第一个样式多行文本溢出剪切
// .news-notice p {
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 4;
//   overflow: hidden;
//   white-space: initial;
// }
.news-notice p.newsNow {
  -webkit-line-clamp: 1;
}

.el-tag {
  // width: 40px;
  width: 65px;
  height: 22px;
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  padding: 0 7px;
  line-height: 20px;
  font-size: 12px;
  color: #409eff;
  border-width: 0px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 3px;
  margin-right: 12px;
  border: 1px solid;
}

.borderBottom {
  border-bottom: 1px solid #e5eaf1;
  margin-top: 24px;
  margin-bottom: 24px;
}

.right-title {
  display: flex;
  justify-content: space-between;
  height: 25px;
  line-height: 25px;
}

// 标题统一样式
.headline {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 25px;
  padding-left: 4px;
}

.newActivities>img {
  width: 342px;
  height: 192px;
  // background-color: pink;
  margin-top: 24px;
  border-radius: 6px;
}

.newActivities-text {
  width: 309px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 16px auto 0px;
  cursor: pointer;
}

.newActivities-text:hover {
  color: #4676f8;
}

.newActivitiesBox:hover {
  cursor: pointer;
  color: #4676f8;
}

.btn-list {
  // margin-top: 24px;
  width: 342px;
  height: 144px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-list-info {
  margin: 0px;
  width: 170px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.btn-list-top {
  margin-top: 24px;
}

// 去除elm组件自带样式
.el-button+.el-button {
  margin-left: 0px;
}

.img-roster {
  width: 100%;
  height: 121px;
  margin-top: 48px;
  cursor: pointer;
}

.laughably-public {
  margin-top: 48px;
}

.laughably-list {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.laughably-list:hover {
  color: #4676f8;
}

.course-list {
  width: 195px;
  height: 173px;
  padding: 16px;
  cursor: pointer;
}

.course-list-img {
  // width: 195px;
  // height: 110px;
  width: 190px;
  height: 115px;
  border-radius: 6px;
}

.course-list-img>img {
  // width: 195px;
  // height: 110px;
  width: 190px;
  height: 115px;
  border-radius: 6px;
}

h4 {
  margin-top: 12px;
  // margin-top: 14px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hot {
  font-size: 12px;
  color: #9b9da2;
}

.mindSmallBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mind-img {
  width: 159px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mind-img-list>img {
  width: 159px;
  height: 100px;
  margin-bottom: 12px;
  border-radius: 6px;
  margin-top: 24px;
  cursor: pointer;
}

.mine-text-list {
  width: 159px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  cursor: pointer;
}

.mineBigImgBox {
  margin-top: 24px;
  width: 324px;
  height: 228px;
}

.bigImg>img {
  width: 341px;
  height: 192px;
  border-radius: 6px;
  margin-bottom: 12px;
  background-color: plum;
  cursor: pointer;
}

.bigImg-text {
  width: 324px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.case {
  margin-top: 24px;
}

.case-list {
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 16px;
}

.case-list:hover {
  color: #4676f8;
  cursor: pointer;
}

.mind-box {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}

.mindArticle {
  margin-top: 16px;
}

.mind-article-list {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #e5eaf1;
  cursor: pointer;
}

.mind-article-list:last-child {
  border-bottom: none;
}

.mind-article-more {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
  line-height: 20px;
  cursor: pointer;
}

.mind-article-list-img>img {
  width: 220px;
  height: 124px;
  border-radius: 6px;
}

.mind-article-list-text {
  margin-left: 24px;
  width: 406px;
  height: 124px;
  display: flex;
  align-items: center;
}

.mind-article-list-text div {
  width: 100%;
}

.mind-article-title {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mind-article-info {
  margin-top: 12px;
  width: 406px;
  font-size: 14px;
  color: #9b9da2;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: initial;
}

.mindArticleBorder-bottom {
  width: 100%;
  height: 1px;
  background: #e5eaf1;
  margin: 16px 0;
}

.teacherTeam-box {
  width: 100%;
  height: 168px;
  margin-top: 10px;
  display: flex;
  border-bottom: 1px solid #e5eaf1;
}

.teacherTeam-box:last-child {
  border-bottom: none;
}

.teacherTeam-img>div>img {
  width: 120px;
  height: 120px;
  margin-top: 22px;
  border-radius: 50%;
}

.teacherName {
  width: 45px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
}

.teacherIntro {
  width: 26px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
  line-height: 20px;
  padding-left: 12px;
}

.teacherTeam-text {
  margin-left: 24px;
  width: 506px;
  display: flex;
  align-items: center;
}

.teacherTeam-text div {
  width: 100%;
}

.teacherTeam-text-info {
  width: 700px;
  font-size: 14px;
  font-weight: 400;
  color: #3b3d41;
  line-height: 20px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  // 多行文本溢出显示省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.hover-item-shadow:hover {
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 6px;
  border: none;
}

.hover-itemFirst:hover {
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(70, 118, 248, 0.2);
  border-radius: 6px;
  border: none;
}

.hover-item-bigImg:hover {
  transform: scale(1.02);
}

.hover-item:hover .hover-item-title {
  color: #4676f8;
}

.hover-item-title {
  // 单行文本溢出显示省略号
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hover-item-img {
  position: relative;
}

.hover-item:hover .hover-item-img::after,
.hover-item:hover .hover-item-img::before {
  display: block;
}

.course {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 24px;
}

.hover-text-cover:hover {
  color: #4676f8;
}

.newsTitleInfo-box {
  height: 380px;
}

.newsInfo {
  height: 350px;
  // border: 1px solid black;
  overflow: hidden;
  white-space: nowrap;
  // text-overflow: ellipsis;
}

.newsInfo p {
  margin-bottom: 0;
}

.newsInfo-item {
  // margin-top: 16px;
  // padding: 16px 16px 19px 16px;
  padding: 30px 16px 19px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5eaf1;
  cursor: pointer;
}

.newsInfo-item:first-child {
  margin-top: 16px;
}

.newsInfo-item:last-child {
  border-bottom: none;
}

.el-button--default {
  color: #606266 !important;
}
</style>
